.order {
  &-status {
    &--wip,
    &--done {
      font-weight: bold;
    }

    &--wip {
      color: $color-warning;
    }

    &--done {
      color: $color-success;
    }

    &--alert {
      color: $color-alert;
    }

    &__global {
      font-size: 1.6rem;
      font-weight: bold;
      margin-bottom: $big-spacing;
    }

    &__step {
      display: flex;
      justify-content: space-between;
      margin-bottom: $big-spacing;
      position: relative;

      &::after {
        @include pseudo-element;
        background: $gray-c;
        height: 1px;
        left: 0;
        top: calc(4.5rem + 1px);
        width: calc(100% - 4rem);
      }

      &-text {
        color: $gray-a;
        display: flex;
        flex-direction: column;
        margin-top: $medium-spacing;
        text-align: center;
      }

      &-complement {
        font-size: .9rem;
        font-weight: bold;
        margin-top: $tiny-spacing;
      }

      &-element {
        align-items: center;
        flex-direction: column;
        position: relative;
        width: 10%;

        &::before {
          @include pseudo-element;
          background: $gray-c;
          height: 3px;
          top: 4.5rem;
          width: 100%;
          z-index: 2;
        }

        .icon {
          height: 4rem;
          position: relative;

          &::before {
            color: $gray-c;
            font-size: 1.8rem;
            position: absolute;
          }
        }

        &--wip,
        &--done,
        &--alert {
          .order-status {
            &__step-text,
            &__step-complement {
              color: $color-default;
            }
          }
        }

        &--wip {
          .order-status {
            &__step-text,
            &__step-complement {
              &::before,
              &::after {
                color: $color-warning;
              }
            }

            &__step-complement {
              color: $color-warning;
            }
          }

          &:not(.order-status__progress-bar) {
            &::before {
              background: $color-warning;
            }
          }

          .icon {
            &::before {
              color: $color-warning;
            }
          }
        }

        &--done {
          .icon {
            &::before {
              color: $color-success;
            }
          }

          .order-status {
            &__step-text,
            &__step-complement {
              &::before,
              &::after {
                color: $color-success;
              }
            }

            &__step-complement {
              color: $color-success;
            }
          }

          &:not(.order-status__progress-bar) {
            &::before {
              background: $color-success;
            }
          }
        }

        &--alert {
          .icon {
            &::before {
              color: $color-alert;
            }
          }

          .order-status {
            &__step-text,
            &__step-complement {
              &::before,
              &::after {
                color: $color-alert;
              }
            }

            &__step-complement {
              color: $color-alert;
            }
          }

          &:not(.order-status__progress-bar) {
            &::before {
              background: $color-alert;
            }
          }
        }
      }
    }

    &__progress-bar {
      &::after {
        @include pseudo-element;
        height: 3px;
        left: 0;
        top: 4.5rem;
        width: 0;
        z-index: 2;
      }

      &--0,
      &--1,
      &--2,
      &--3 {
        &::after {
          background: $color-warning;
        }
      }

      &--1 {
        &::after {
          width: 25%;
        }
      }

      &--2 {
        &::after {
          width: 50%;
        }
      }

      &--3 {
        &::after {
          width: 75%;
        }
      }

      &--4 {
        &::after {
          background: $color-success;
          width: 100%;
        }
      }
    }
  }
}

@import 'media-queries';
