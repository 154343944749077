@media screen and (max-width: map-get($breakpoints, 'small')) {
  .navigation {
    background: $color-delagrave-gray-darker;
    min-height: 100vh;
    overflow-y: auto;
    padding-top: $huge-spacing;
    position: absolute;
    right: -100vw;
    transition: right .25s;
    width: 100vw;
    z-index: 2;

    &__menu {
      > .navigation {
        &__menu-element {
          &:not(:last-of-type) {
            border-color: darken($color-delagrave-gray-darker, 10%);
          }
        }
      }

      &--mini {
        border: 0;
        position: relative;

        .icon {
          &--expand {
            display: none;
          }
        }
      }

      &-element {
        &--main {
          max-height: unset !important;
          width: 100%;

          .navigation {
            &__menu-text {
              display: flex;
              font-size: .9rem;
              left: unset !important;
              opacity: 1 !important;
              padding-left: 0;
            }

            &__text-wrapper {
              background: $color-delagrave-gray-darker !important;
              display: flex !important;
              left: unset !important;
              position: relative !important;
              right: unset !important;
              width: 100% !important;
            }

            &__sub {
              .icon {
                opacity: 1 !important;
              }
            }
          }

          .button {
            align-items: center;
            display: flex;
            padding: 0 $default-spacing;
            width: 100%;
          }

          .icon {
            background: $color-delagrave-gray-darker !important;
          }
        }

        &--dropdown {
          > .navigation {
            &__menu-link {
              display: none;
            }
          }
        }
      }

      &__link {
        flex-direction: row;
      }
    }

    &__mobile-bar {
      background: $color-delagrave-gray-dark;
      display: block;
      height: $huge-spacing;
      left: 0;
      position: fixed;
      top: 0;
      width: 100vw;
      z-index: 3;
    }

    &--open {
      right: 0;
      transition: right .25s;
    }
  }
}