@import 'app/settings/colors';
@import "node_modules/@fortawesome/fontawesome-pro/scss/fontawesome.scss";
@import "node_modules/@fortawesome/fontawesome-pro/scss/solid.scss";
@import "node_modules/@fortawesome/fontawesome-pro/scss/brands.scss";

// E-vinrude

@import 'e-vinrude/e-vinrude';

// App

@import 'app/settings/fonts';
@import 'app/settings/reset';
@import 'app/settings/mixins';
@import 'app/text/text';
@import 'app/icon/icon';
@import 'app/button/button';
@import 'app/breadcrumbs/breadcrumbs';
@import 'app/logo/logo';
@import 'app/layout/layout';
@import 'app/side-bar/side-bar';
@import 'app/title/title';
@import 'app/list/list';
@import 'app/navigation/navigation';
@import 'app/content/content';
@import 'app/section/section';
@import 'app/form/form';
@import 'app/table/table';
@import 'app/table/table-table';
@import 'app/pagination/pagination';
@import 'app/order/order';
@import 'app/alert/alert';

// Vendor

@import 'vendor/vendor';
