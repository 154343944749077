.ev {
  &-color {
    @each $name, $color in $colors {

      // Couleur du texte

      &__text {
        &--#{$name} {
          color: $color !important;
        }

        &-hover {
          &--#{$name} {
            &:hover,
            &:focus,
            &:active {
              color: $color !important;
            }
          }
        }
      }

      // Couleur de l'arrière plan

      &__background {
        &--#{$name} {
          background-color: $color !important;
        }

        &-hover {
          &--#{$name} {
            &:hover,
            &:focus,
            &:active {
              background-color: $color !important;
            }
          }
        }
      }

      // Couleur des bordures //

      &__border {
        &--#{$name} {
          border-color: $color !important;
        }

        &-hover {
          &--#{$name} {
            &:hover,
            &:focus,
            &:active {
              border-color: $color !important;
            }
          }
        }
      }

      // Couleur du "before" //

      &__before {
        &--#{$name} {
          &::before {
            color: $color !important;
          }
        }

        &-hover {
          &--#{$name} {
            &:hover,
            &:focus,
            &:active {
              &::before {
                color: $color !important;
              }
            }
          }
        }
      }

      // Couleur du "after" //

      &__after {
        &--#{$name} {
          &::after {
            color: $color !important;
          }
        }

        &-hover {
          &--#{$name} {
            &:hover,
            &:focus,
            &:active {
              &::after {
                color: $color !important;
              }
            }
          }
        }
      }
    }
  }

  // Ne pas afficher les bordures, ombres et pseudo-éléments //

  &-border {
    &--none {
      border: 0 !important;
    }
  }

  &-before {
    &--none {
      &::before {
        display: none !important;
      }
    }
  }

  &-after {
    &--none {
      &::after {
        display: none !important;
      }
    }
  }

  &-shadow {
    &--none {
      box-shadow: none !important;
    }
  }
}