// "Spacings" de base //

$default-spacing: 1rem !default;
$medium-spacing: $default-spacing * 1.5 !default;
$big-spacing: $default-spacing * 2 !default;
$huge-spacing: $default-spacing * 3 !default;
$small-spacing: $default-spacing / 2 !default;
$tiny-spacing: $default-spacing / 4 !default;

// Media-queries "breakpoints" //

$breakpoints: (
  'medium': 90rem,
  'small': 50rem,
) !default;
