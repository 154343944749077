@media screen and (max-width: map-get($breakpoints, 'small')) {
  .list {
    &__information,
    &__action-form {
      justify-content: center;
    }

    &__action-form {
      .form {
        &__field {
          &--select {
            max-width: 9rem;
          }
        }
      }
    }
  }
}