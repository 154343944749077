@media screen and (max-width: map-get($breakpoints, 'medium')) {
  .title {
    font-size: 1.2rem;
    line-height: 1.6rem;

    &__complement {
      font-size: 1.2rem;
    }

    &__sav {
      .sav {
        &__title {
          font-size: 1.8rem;
        }

        &__information {
          font-size: .7rem;
        }
      }
    }
  }
}

@media screen and (max-width: map-get($breakpoints, 'small')) {
  .title {
    font-size: 1.1rem;
    line-height: 1.4rem;

    &__sub {
      flex-direction: column;

      &-element {
        font-size: .9rem;

        &::after {
          display: none;
        }
      }
    }

    &__complement {
      font-size: $default-spacing;
    }

    &__sav {
      flex-wrap: wrap;
      height: auto;
      justify-content: center;
      padding: $small-spacing $default-spacing;
      position: relative;
      right: unset;
      width: 100%;

      .sav {
        &__title,
        &__information {
          flex-wrap: wrap;
          font-size: .8rem;
        }

        &__title {
        }

        &__information {
          align-items: center;
          flex-direction: row;

          &::before {
            content: '-';
            margin-right: $tiny-spacing;
          }
        }
      }
    }

    .icon {
      max-width: 2.4rem;
      min-width: 2.4rem;
    }
  }
}