.alert {
  background: $color-default;
  color: $white;
  font-size: 1.1rem;
  font-weight: bold;
  padding: $default-spacing $big-spacing;
  text-align: center;

  &--success {
    background: $color-success;
  }

  &--warning {
    background: $color-warning;
  }

  &--error {
    background: $color-alert;
  }

  &__wrapper {
    height: auto;
    left: 0;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: 15;
  }
}

@import 'media-queries';