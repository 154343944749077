@media screen and (max-width: map-get($breakpoints, 'medium')) {
  .table {
    &__filter {
      .button {
        min-height: 2.5rem;
      }
    }
  }

  .table-table {
    &__content {
      .table-table {
        &__cell {
          font-size: .8rem !important;
        }
      }
    }
  }
}

@media screen and (max-width: map-get($breakpoints, 'small')) {
  .table {
    max-width: 100%;

    .text {
      &__label {
        display: flex !important;
      }

      &__value {
        font-size: .8rem;
        margin-left: $small-spacing;
      }
    }

    &__row {
      flex-direction: column;
      padding: $medium-spacing $default-spacing !important;
      position: relative;

      &:not(:first-of-type) {
        border-bottom: 1px solid $gray-d;
      }

      &:not(.table__row--header) {
        .table {
          &__column {
            flex-wrap: wrap;
            padding-bottom: 0;
            padding-top: 0;

            &--fullname {
              flex-direction: column;

            }

            &--checkbox {
              background: $color-delagrave-gray-darker !important;
              left: 0;
              margin: 0;
              min-width: auto;
              padding: $tiny-spacing $small-spacing;
              position: absolute;
              top: 0;

              .text {
                &__label {
                  color: $white;
                  margin-left: $small-spacing;
                }
              }
            }
          }
        }
      }

      &--header {
        display: none;
      }

      &--detail {
        padding: $default-spacing !important;
      }
    }

    &__column {
      border-top: 0 !important;
      font-weight: bold;
      max-width: 100%;
      min-width: 100%;

      &::after {
        display: none !important;
      }

      &--order,
      &--name,
      &--id {
        margin-bottom: $tiny-spacing;

        .text {
          &__label,
          &__value {
            font-size: $default-spacing;
          }
        }
      }

      &--date {
        .form {
          &__field {
            margin-bottom: $tiny-spacing;
            margin-top: $tiny-spacing;
            max-width: 8rem;
          }
        }
      }

      &--action  {
        flex-direction: column;
        margin-top: $small-spacing;

        .table {
          &__action {
            margin: $tiny-spacing 0 !important;
            max-width: 100%;
            min-width: 100%;
          }
        }
      }

      &:not(.table__column--action) {
        margin: $tiny-spacing 0;
      }

      &:nth-of-type(2) {
        margin-top: $default-spacing;
      }
    }

    &__filter {
      padding-left: $default-spacing !important;
      padding-right: $default-spacing !important;
    }

    .button {
      &__text {
        display: flex;
      }

      &--table {
        &-download {
          margin-bottom: $small-spacing;

          .icon {
            &::before {
              font-size: $default-spacing;
            }
          }
        }
      }
    }

    .icon {
      &--alert {
        margin-left: $small-spacing;
        position: relative;
        right: unset;
      }
    }
  }

  .table-table {
    &__row {
      display: flex;
      flex-direction: column;
      max-width: calc(100vw - 3rem);
      padding-bottom: $default-spacing;
      padding-top: $huge-spacing;
      position: relative;
    }

    &__cell {
      display: flex;

      &::after {
        display: none !important;
      }

      &:not(.table-table__cell--checkbox) {
        font-size: .8rem;
        justify-content: center;
        padding: $tiny-spacing $default-spacing;
      }

      &-label {
        display: block !important;

        &::before {
          display: none;
        }
      }

      &--checkbox {
        display: flex;
        height: 2.2rem;
        left: 0 !important;
        padding: 0;

        .table-table {
          &__cell-label {
            margin-right: 0;
            padding-left: $small-spacing;
          }
        }

        .form {
          &__field-container {
            flex-direction: row !important;
            left: 0 !important;
            max-width: unset;
          }

          &__checkbox {
            left: 0;
            min-height: $huge-spacing;
            min-width: unset;
            right: unset;
          }

          &__label {
            color: white;
            max-width: unset;
            min-width: unset;
            padding-left: $medium-spacing;

            &::before,
            &::after {
              left: 0;
            }
          }
        }
      }

      &--action {
        min-width: unset;
        padding: 0 !important;
        right: 0;
        z-index: 1;

        .button {
          margin: 0 !important;
        }
      }
    }

    &__content {
      .table-table {
        &__cell {
          &:not(.table-table__cell--checkbox) {
            flex-wrap: wrap;
          }

          &--name {
            font-size: $default-spacing !important;
          }

          &--checkbox,
          &--action {
            position: absolute;
            top: 0;
          }

          &--action {
            background: $gray-c;
            justify-content: flex-end;
            width: 100%;
          }

          &--checkbox {
            background: $gray-33;
            margin-bottom: $default-spacing;
            z-index: 2;
          }
        }

        &__action {
          margin: 0;

          .button {
            height: 2.2rem;
          }
        }
      }
    }

    &__header {
      display: none;
    }
  }
}