.list {
  &__element {
    display: flex;
    flex-direction: column;
    justify-content: center;

    &:not(:last-of-type) {
      border-bottom: 1px solid $gray-fa;
    }

    &-title {
      height: $huge-spacing;

      &--expandable {
        cursor: pointer;
        font-size: 1.1rem;
        font-weight: bold;

        + .list {
          &__element-content {
            display: none;
          }
        }
      }
    }

    &-content {
      padding: 0 0 $big-spacing;
    }

    &--dotted {
      .list {
        &__element {
          &-title {
            &::before {
              color: $color-delagrave-red;
              content: '•';
              font-size: 1.2rem;
              margin-right: $small-spacing;
            }
          }

          &-content {
            padding: 0 1.1rem $big-spacing;
          }
        }
      }
    }
  }

  &__action-form,
  &__display-form,
  &__information,
  &__result {
    display: flex;
  }

  &__information,
  &__action-form,
  &__pagination {
    margin-bottom: 0 !important;
  }

  &__information,
  &__action-form {
    align-items: center;
    flex-wrap: wrap;
    font-size: .8rem;
    font-weight: bold;
  }

  &__information {
    justify-content: flex-end;
  }

  &__action-form {
    &--disabled {
      opacity: .4;
    }
  }

  &__pagination {
    justify-content: center;
  }

  &__display-form {
    &::before {
      color: $color-delagrave-red;
      content: '/';
      line-height: $big-spacing;
      margin: 0 $small-spacing;
    }
  }

  &__action-form,
  &__display-form {
    .form {
      &__label {
        color: $color-default;
        font-size: .8rem;
        margin-right: $small-spacing;
      }

      &__field {
        line-height: $big-spacing;
        max-height: $big-spacing;
        min-height: $big-spacing;
        width: unset;

        option {
          height: 100%;
        }
      }
    }
  }

  &__result {
    line-height: $big-spacing;
    white-space: nowrap;
  }
}

@import 'media-queries';