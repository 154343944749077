@media screen and (max-width: map-get($breakpoints, 'medium')) {
  .section {
    &__content {
      &--list-chapo {
        .text {
          &__label,
          &__value {
            font-size: .9rem;
          }
        }
      }
    }

    &--dashboard {
      &-link {
        .button {
          font-size: 1.4rem;
          padding: $default-spacing $default-spacing $small-spacing;

          &__text {
            &:first-of-type {
              font-size: 1.3rem;
              margin-top: 1.3rem;
            }
          }
        }

        .icon {
          &::before {
            font-size: 3.5rem;
          }
        }
      }
    }
  }
}

@media screen and (max-width: map-get($breakpoints, 'small')) {
  .section {
    margin: $default-spacing 0;

    &__header {
      flex-wrap: wrap;

      .button {
        min-height: 2.4rem;
      }

      &-button-wrapper {
        width: 100%;

        .button {
          flex-grow: 2;
        }
      }
    }

    &__content {
      padding-left: $default-spacing !important;
      padding-right: $default-spacing !important;

      &--list-chapo {
        .text {
          &__wrapper {
            flex-wrap: wrap;
            margin-bottom: $tiny-spacing;
          }

          &__label,
          &__value {
            font-size: .8rem;
          }
        }
      }
    }

    &--dashboard {
      &-link {
        .button {
          font-size: 1.3rem;

          &__text {
            &:first-of-type {
              margin-top: $default-spacing;
            }
          }
        }

        .icon {
          &::before {
            font-size: 4rem;
          }
        }
      }
    }
  }
}