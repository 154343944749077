.text {
  font-size: $default-spacing;

  &--chapo {
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: $default-spacing;
  }

  &--default {
    font-size: .9rem !important;
  }

  &--small {
    font-size: .8rem !important;
  }

  &--tiny {
    font-size: .7rem !important;
  }

  &__wrapper {
    margin-bottom: $small-spacing;

    .text {
      &__label {
        margin-bottom: $tiny-spacing;

        &::before {
          color: $color-default;
          content: '•';
          margin-right: $tiny-spacing;
        }
      }
    }
  }

  &--space {
    margin-bottom: $default-spacing;
  }

  &--paragraph-title {
    font-weight: bold;
    margin-bottom: $small-spacing;
    margin-top: $small-spacing;
  }

  &__label {
    color: $gray-90;
    font-size: .8rem;
  }

  &__value {
    font-size: .9rem;
    font-weight: bold;
  }

  &__link {
    align-items: center;
    color: $color-delagrave-red;
    display: inline-flex;

    &:hover,
    &:active,
    &:focus {
      color: $color-default;
    }
  }

  &--copyright {
    bottom: 7rem;
    color: $color-delagrave-gray-light;
    display: flex;
    font-size: .8rem;
    font-style: italic;
    justify-content: center;
    opacity: 1;
    padding: $small-spacing 0;
    position: relative;
    transition: opacity .25s, bottom .25s;
    white-space: nowrap;
  }
}

@import 'media-queries';