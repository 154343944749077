@import 'mixins';

.ev {

  // Propriété "display" //

  &-display {
    &--none {
      display: none !important;
    }

    &--inline {
      display: inline !important;
    }

    &--block {
      display: block !important;
    }

    &--inline-block {
      display: inline-block !important;
    }

    &--flex {
      display: flex !important;
    }

    &--inline-flex {
      display: inline-flex !important;
    }
  }

  // Masquer visuellement un élément //

  &-visibility {
    &--hidden {
      visibility: hidden !important;
    }

    &--visible {
      visibility: visible !important;
    }
  }

  // Afficher un élément en dehors du viewport //

  &-position-hide {
    @include position-hide;
  }
}

// Gestion du display en fonction des différentes tailles d'écrans //

@import 'media-queries';