// Variables //
$properties-min-rem-value: 4 !default;
$properties-max-rem-value: 30 !default;
$properties-min-order-value: 0 !default;
$properties-max-order-value: 15 !default;

$properties-size-values: (
  'tiny': $tiny-spacing,
  'small': $small-spacing,
  'default': $default-spacing,
  'medium': $medium-spacing,
  'big': $big-spacing,
  'huge': $huge-spacing,
  'half': 50%,
  'tier': calc(100% / 3),
  'tier-2': calc(100% / 1.5),
  'quarter': 25%,
  'quarter-3': 75%,
  'sixth': calc(100% / 6),
  'sixth-5': calc(500% / 6),
  'full': 100%,
  'viewport-height': 100vh,
  'viewport-width': 100vw,
  'unset': unset,
  'none': 0,
  'auto': auto,
) !default;

// flex-basis //

@for $i from $properties-min-rem-value through $properties-max-rem-value {
  .ev-basis--#{$i} {
    flex-basis: #{$i}rem !important;
  }
}

@each $name, $value in $properties-size-values {
  .ev-basis--#{$name} {
    flex-basis: #{$value} !important;
  }
}

// flex-grow //

@for $i from 0 through 7 {
  .ev-grow--#{$i} {
    flex-grow: #{$i} !important;
  }
}

// flex-shrink //

@for $i from -1 through 7 {
  .ev-shrink--#{$i} {
    flex-shrink: #{$i} !important;
  }
}

// flex-direction //

.ev-direction {
  &--row {
    flex-direction: row !important;
  }

  &--column {
    flex-direction: column !important;
  }

  &--row-reverse {
    flex-direction: row-reverse !important;
  }

  &--column-reverse {
    flex-direction: column-reverse !important;
  }
}

// justify-content //

.ev-justify {
  &--center {
    justify-content: center !important;
  }

  &--start {
    justify-content: flex-start !important;
  }

  &--end {
    justify-content: flex-end !important;
  }

  &--between {
    justify-content: space-between !important;
  }

  &--around {
    justify-content: space-around !important;
  }

  &--evenly {
    justify-content: space-evenly !important;
  }
}

// flex-align //

.ev-align {
  &--center {
    align-items: center !important;
  }

  &--start {
    align-items: flex-start !important;
  }

  &--end {
    align-items: flex-end !important;
  }

  &--baseline {
    align-items: baseline !important;
  }

  &--stretch {
    align-items: stretch !important;
  }
}

// order //

@for $i from $properties-min-order-value through $properties-max-order-value {
  .ev-order {
    &--#{$i} {
      order: #{$i} !important;
    }
  }
}

// flex-wrap //

.ev-wrap {
  flex-wrap: wrap !important;
}

.ev-nowrap {
  flex-wrap: nowrap !important;
}

// margin //

@for $i from $properties-min-rem-value through $properties-max-rem-value {
  .ev-margin {
    &--all {
      &-#{$i} {
        margin: #{$i}rem !important;
      }
    }

    &--top {
      &-#{$i} {
        margin-top: #{$i}rem !important;
      }
    }

    &--right {
      &-#{$i} {
        margin-right: #{$i}rem !important;
      }
    }

    &--bottom {
      &-#{$i} {
        margin-bottom: #{$i}rem !important;
      }
    }

    &--left {
      &-#{$i} {
        margin-left: #{$i}rem !important;
      }
    }

    &--tb {
      &-#{$i} {
        margin-bottom: #{$i}rem !important;
        margin-top: #{$i}rem !important;
      }
    }

    &--lr {
      &-#{$i} {
        margin-left: #{$i}rem !important;
        margin-right: #{$i}rem !important;
      }
    }
  }
}

@each $name, $value in $properties-size-values {
  .ev-margin {
    &--all {
      &-#{$name} {
        margin: #{$value} !important;
      }
    }

    &--top {
      &-#{$name} {
        margin-top: #{$value} !important;
      }
    }

    &--right {
      &-#{$name} {
        margin-right: #{$value} !important;
      }
    }

    &--bottom {
      &-#{$name} {
        margin-bottom: #{$value} !important;
      }
    }

    &--left {
      &-#{$name} {
        margin-left: #{$value} !important;
      }
    }

    &--tb {
      &-#{$name} {
        margin-bottom: #{$value} !important;
        margin-top: #{$value} !important;
      }
    }

    &--lr {
      &-#{$name} {
        margin-left: #{$value} !important;
        margin-right: #{$value} !important;
      }
    }
  }
}

// padding //

@for $i from $properties-min-rem-value through $properties-max-rem-value {
  .ev-padding {
    &--all {
      &-#{$i} {
        padding: #{$i}rem !important;
      }
    }

    &--top {
      &-#{$i} {
        padding-top: #{$i}rem !important;
      }
    }

    &--right {
      &-#{$i} {
        padding-right: #{$i}rem !important;
      }
    }

    &--bottom {
      &-#{$i} {
        padding-bottom: #{$i}rem !important;
      }
    }

    &--left {
      &-#{$i} {
        padding-left: #{$i}rem !important;
      }
    }

    &--tb {
      &-#{$i} {
        padding-bottom: #{$i}rem !important;
        padding-top: #{$i}rem !important;
      }
    }

    &--lr {
      &-#{$i} {
        padding-left: #{$i}rem !important;
        padding-right: #{$i}rem !important;
      }
    }
  }
}

@each $name, $value in $properties-size-values {
  .ev-padding {
    &--all {
      &-#{$name} {
        padding: #{$value} !important;
      }
    }

    &--top {
      &-#{$name} {
        padding-top: #{$value} !important;
      }
    }

    &--right {
      &-#{$name} {
        padding-right: #{$value} !important;
      }
    }

    &--bottom {
      &-#{$name} {
        padding-bottom: #{$value} !important;
      }
    }

    &--left {
      &-#{$name} {
        padding-left: #{$value} !important;
      }
    }

    &--tb {
      &-#{$name} {
        padding-bottom: #{$value} !important;
        padding-top: #{$value} !important;
      }
    }

    &--lr {
      &-#{$name} {
        padding-left: #{$value} !important;
        padding-right: #{$value} !important;
      }
    }
  }
}

// width //

@for $i from $properties-min-rem-value through $properties-max-rem-value {
  .ev-width--#{$i} {
    width: #{$i}rem !important;
  }
}

@each $name, $value in $properties-size-values {
  .ev-width--#{$name} {
    width: #{$value} !important;
  }
}

// min-width //

@for $i from $properties-min-rem-value through $properties-max-rem-value {
  .ev-min-width--#{$i} {
    min-width: #{$i}rem !important;
  }
}

@each $name, $value in $properties-size-values {
  .ev-min-width--#{$name} {
    min-width: #{$value} !important;
  }
}

// max-width //

@for $i from $properties-min-rem-value through $properties-max-rem-value {
  .ev-max-width--#{$i} {
    max-width: #{$i}rem !important;
  }
}

@each $name, $value in $properties-size-values {
  .ev-max-width--#{$name} {
    max-width: #{$value} !important;
  }
}

// height //

@for $i from $properties-min-rem-value through $properties-max-rem-value {
  .ev-height--#{$i} {
    height: #{$i}rem !important;
  }
}

@each $name, $value in $properties-size-values {
  .ev-height--#{$name} {
    height: #{$value} !important;
  }
}

// min-height //

@for $i from $properties-min-rem-value through $properties-max-rem-value {
  .ev-min-height--#{$i} {
    min-height: #{$i}rem !important;
  }
}

@each $name, $value in $properties-size-values {
  .ev-min-height--#{$name} {
    min-height: #{$value} !important;
  }
}

// max-height //

@for $i from $properties-min-rem-value through $properties-max-rem-value {
  .ev-max-height--#{$i} {
    max-height: #{$i}rem !important;
  }
}

@each $name, $value in $properties-size-values {
  .ev-max-height--#{$name} {
    max-height: #{$value} !important;
  }
}

@import 'media-queries';