.table-table {
  overflow: hidden;

  .table-table {
    &__row,
    &__cell {
      position: relative;
    }

    &__cell {
      justify-content: center;
      padding: $small-spacing $default-spacing;
      text-align: center;

      &-content {
        margin-left: auto;
        margin-right: auto;
      }

      &-label {
        color: $gray-a;
        display: none;
        margin-right: $small-spacing;

        &::before {
          color: $color-delagrave-red;
          content: '•';
          margin-right: $tiny-spacing;
        }
      }

      &--left-align {
        justify-content: left !important;
        min-width: 10rem;
        text-align: left !important;
      }

      &--action {
        white-space: nowrap;
      }

      &--checkbox {
        .form {
          &__field-container {
            flex-direction: column;
          }

          &__field {
            min-height: $big-spacing;
          }
        }
      }
    }

    &__action {
      display: inline-block;
      margin: 0 $tiny-spacing;
      max-width: 4rem;
      min-width: 4rem;
    }

    &__content {
      .table-table {
        &__row {
          &:nth-of-type(2n+1) {
            background: $gray-fa;
          }
        }

        &__cell {
          font-size: .9rem;
          font-weight: bold;
          vertical-align: middle;

          &--action,
          &--checkbox {
            &::before {
              display: none !important;
            }
          }

          &:not(:last-of-type){
            &::after {
              @include pseudo-element;
              background: $gray-d;
              height: $default-spacing;
              right: 0;
              top: calc(50% - .5rem);
              width: 1px;
            }
          }
        }
      }

      .button {
        &--action {
          display: inline-block;

          &:not(:first-of-type) {
            margin-left: $small-spacing;
          }
        }
      }
    }

    &__header {
      .table-table {
        &__cell {
          color: $gray-a;
          font-size: .8rem;
          vertical-align: bottom;

          &:not(.table-table__cell--checkbox) {
            padding-bottom: $default-spacing;
          }

          &--action {
            .table-table {
              &__cell-content {
                width: 100%;
              }
            }
          }

          &--sort {
            &-by {
              color: $black;

              a {
                display: inline-block;
                text-align: center;
                white-space: normal;
                word-wrap: normal;

                &::after {
                  @include pseudo-element;
                  border-bottom: 0;
                  border-left: .3rem solid transparent;
                  border-right: .3rem solid transparent;
                  border-top: .3rem solid $gray-d;
                  bottom: $small-spacing;
                  height: 0;
                  left: calc(50% - .3rem);
                  transition: border-top-color .25s;
                  width: 0;
                }

                &:hover {
                  color: $color-delagrave-red;

                  &::after {
                    border-top-color: $black;
                  }
                }
              }

              .table {
                &__column {
                  &--sort {
                    &-down,
                    &-up {
                      color: $color-delagrave-red;
                      font-weight: bold;

                      &::after {
                        border-top-color: $color-delagrave-red;
                      }
                    }

                    &-up {
                      &::after {
                        transform: rotate(180deg);
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .icon {
      font-size: $default-spacing;

      &--alert {
        position: absolute;
        right: .4rem;
      }
    }
  }

  .button {
    &--table {
      &-action {
        .button {
          &__text {
            display: none;
          }
        }
      }

      &-download {
        .icon {
          font-size: 1.2rem;
        }
      }
    }
  }
}

@import 'media-queries';