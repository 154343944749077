@media screen and (max-width: map-get($breakpoints, 'medium')) {
  .button {
    font-size: .9rem;
    min-height: $big-spacing;
    padding: $tiny-spacing $medium-spacing;

    &--icon {
      padding: 0;
    }

    &--text {
      font-size: 1.1rem;
      min-height: 1.4rem;
    }

    &--account {
      font-size: .9rem;
      padding-right: $big-spacing;
    }

    &--table-action {
      padding: $tiny-spacing $small-spacing;
    }
  }
}

@media screen and (max-width: map-get($breakpoints, 'small')) {
  .button {
    font-size: .8rem;
    min-height: 1.8rem;
    padding: $tiny-spacing $default-spacing;

    &--icon {
      padding: 0;
    }
  }
}