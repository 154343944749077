@import 'mixins';

.ev-text {

  // Taille //

  @each $name, $font-size in $font-sizes {
    &__size {
      &--#{$name} {
        font-size: $font-size !important;
      }
    }
  }

  // Graisse //

  &__weight {
    &--normal {
      font-weight: $font-weight-normal !important;
    }

    &--bold {
      font-weight: $font-weight-bold;
    }
  }

  // Style //

  &__style {
    &--normal {
      font-style: normal !important;
    }

    &--italic {
      font-style: italic;
    }
  }

  // Transformation //

  &__transform {
    &--capitalize {
      text-transform: capitalize;
    }

    &--uppercase {
      text-transform: uppercase;
    }

    &--lowercase {
      text-transform: lowercase;
    }

    &--none {
      text-transform: none !important;
    }
  }

  // Décoration //

  &__decoration {
    &--underline {
      text-decoration: underline;
    }

    &--overline {
      text-decoration: overline;
    }

    &--none {
      text-decoration: none !important;
    }
  }

  // Ligne unique + points de suspension //

  &__ellipsis {
    @include typography-ellipsis;
  }

  // Comportement de retour à la ligne du texte //

  &__wrap {
    &--nowrap {
      white-space: nowrap !important;
    }

    &--wrap {
      white-space: normal !important;
    }
  }

  // Alignement du texte //

  &__align {
    &--left {
      text-align: left;
      justify-content: left;
    }

    &--right {
      text-align: right;
      justify-content: right;
    }

    &--center {
      text-align: center;
      justify-content: center;
    }

    &--justify {
      text-align: justify;
    }
  }

  // Ombre //

  &__shadow {
    &--none {
      text-shadow: none !important;
    }
  }
}

// Alignement du texte en fonction des différentes tailles d'écrans //

@import 'media-queries';