.form {
  &__field,
  &__search {
    width: 100%;
  }

  &__label {
    color: $gray-80;
    font-size: .85rem;
    margin-bottom: $tiny-spacing;

    &--inline {
      align-items: center;
      display: flex;
      margin-bottom: 0;
    }
  }

  &__field {
    background: $gray-e;
    border: 1px solid $color-delagrave-gray;
    color: $color-default;
    line-height: 2.5rem;
    min-height: 2.5rem;
    padding: 0 $small-spacing;
    transition: border .25s, background .25s;

    &-container {
      display: flex;
      flex-direction: column;
      margin-bottom: $default-spacing;

      &:not(.ev-element) {
        margin-bottom: $default-spacing;
      }

      &--checkbox {
        align-items: center;
        display: flex;
        flex-direction: row;

        .form {
          &__field,
          &__label {
            &:hover {
              cursor: pointer;
            }
          }

          &__field {
            margin: 0;
            width: auto;
          }

          &__label {
            font-size: .9rem;
            margin-bottom: 0;
            margin-left: $small-spacing;
          }
        }
      }

      &--radio {
        align-items: center;
        flex-direction: row;

        &:not(:last-of-type) {
          margin-right: $big-spacing;
        }

        .form {
          &__field {
            margin: 0 $small-spacing 0 0;
            min-height: unset;
            width: auto;
          }

          &__label {
            margin-bottom: 0;
          }
        }
      }

      &--review {
        [type="radio"] {
          &:not(:checked),
          &:checked {
            left: 0;
            opacity: .01;
            position: absolute;

            + .form {
              &__label {
                align-items: center;
                cursor: pointer;
                color: $color-default;
                font-family: 'din-regular';
                display: flex;
                position: relative;
                user-select: none;
                white-space: nowrap;

                &::before {
                  border-radius: 50%;
                  font-family: 'Font Awesome 5 Pro';
                  font-size: $big-spacing;
                  padding: $small-spacing;
                  position: relative;
                  display: block;
                  color: white;
                  margin-right: $small-spacing;
                  transition: opacity .15s, transform .15s;
                }

                &.fa {
                  &-frown {
                    &::before {
                      background: $color-alert;
                    }
                  }

                  &-smile {
                    &::before {
                      background: $color-warning;
                    }
                  }

                  &-laugh {
                    &::before {
                      background: $color-success;
                    }
                  }
                }
              }
            }
          }

          &:not(:checked) {
            + .form {
              &__label {
                &::before {
                  opacity: .5;
                  transform: scale(.7);
                }
              }
            }

            &:hover {
              + .form {
                &__label {
                  &::before {
                    opacity: 1;
                    transform: scale(1);
                  }
                }
              }
            }
          }
        }
      }
    }

    &-range-wrapper {
      align-items: center;
      display: flex;
      justify-content: space-between;
      position: relative;

      .form {
        &__label {
          &:first-of-type {
            left: -1.25rem;
            position: absolute;
            top: $small-spacing;
          }
        }

        &__field {
          max-width: calc(50% - 1rem);
        }
      }
    }

    &--text-area {
      min-height: 10rem;
      resize: none;
    }

    &:focus {
      background: $white;
      border-color: $color-delagrave-red;
      outline: 0;
      transition: border .25s, background .25s;
    }
  }

  &__search {
    margin: 0 $default-spacing;

    .form {
      &__field {
        background: $white;
        border-right: 0;
        color: $color-default;
        line-height: 2.2rem;
        min-height: 2.2rem;
        transition: background .25s;

        &:focus {
          background: $white;
          color: $color-default;
          transition: background .25s;
        }
      }

      &__submit {
        background: $color-delagrave-red;
        border-left: 1px solid $color-delagrave-red;
        padding: 0;
        transition: border .25s, background .25s;
        width: 2.5rem;

        &:hover,
        &:active,
        &:focus {
          background: $color-default;
          border-left: 1px solid $color-default;
          transition: border .25s, background .25s;
        }
      }
    }
  }

  &__review {
    display: flex;
    margin-bottom: $default-spacing;
  }

  &__attachment {
    &-list {
      margin: $default-spacing 0;

      &-element {
        font-weight: bold;
        margin: $small-spacing 0;
        word-break: break-all;

        &::before {
          color: $color-delagrave-red;
          content: '•';
          margin-right: $small-spacing;
        }
      }
    }
  }

  &--filter {
    .form {
      &__label {
        color: $white;
      }
    }
  }
}

@import 'media-queries';