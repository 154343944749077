.title {
  align-items: center;
  color: $white;
  display: flex;
  font-size: 1.4rem;

  &__text {
    align-items: center;
    display: flex;
    flex-shrink: 0;
    max-width: 100%;

    &--complement {
      &::before {
        content:'-';
        margin: 0 $small-spacing;
      }
    }
  }

  &__complement {
    color: $gray-50;
    font-size: 1.6rem;
    font-weight: bold;
    padding: $big-spacing;
  }

  &__sub {
    margin-top: $small-spacing;

    &-element {
      &:not(:last-of-type) {
        &::after {
          color: $blue;
          content: '/';
          margin: 0 $default-spacing;
        }
      }
    }

    &-value {
      margin-left: $small-spacing;
    }
  }

  &__sav {
    background: $color-alert;
    color: $white;
    display: flex;
    height: $huge-spacing;
    padding: 0 $big-spacing;
    position: absolute;
    right: 0;

    .sav {
      &__title,
      &__information {
        display: flex;
      }

      &__title {
        align-items: center;
        font-size: 2.2rem;
        margin-right: $small-spacing;
      }

      &__information {
        flex-direction: column;
        font-size: .8rem;
        font-weight: bold;
        justify-content: center;
      }

      &__id {
        margin-right: $tiny-spacing;
      }
    }
  }

  .icon {
    background: $color-delagrave-red;
    min-height: $huge-spacing;
    width: $huge-spacing
  }

  .button {
    &--text {
      font-size: 1.2rem;
      min-height: 1.6rem;
    }
  }

  &--text-standard {
    color: $color-delagrave-red;
    margin: $huge-spacing 0 $default-spacing;
  }
}

@import 'media-queries';