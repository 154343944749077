// Marges internes et externes des "sections" //

$ev-section-padding: $big-spacing !default;
$ev-section-margin: $default-spacing !default;

.ev-section {
  display: flex;
  margin: $ev-section-margin;
  max-width: 100vw;
  padding: $ev-section-padding;

  &--column {
    flex-direction: column;
  }

  &__header,
  &__content {
    padding: 0 $ev-section-padding;
  }

  &__header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    position: relative;
    width: 100%;
  }

  &__content {
    max-width: 100%;
  }

  &__title {
    flex-basis: 0;
    flex-grow: 1;
    line-height: 100%;
  }
}