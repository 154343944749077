.content {
  padding: $big-spacing;

  &__wrapper,
  &__navigation {
    width: 100%;
  }

  &__wrapper {
    background-color: $gray-e;
    height: 100%;
    max-width: calc(100vw - 18rem);
    min-height: 100vh;
    overflow-y: auto;
    position: absolute;
    right: 0;
    transition: max-width .25s;
  }

  &__navigation {
    background: $white;
    border-bottom: 1px solid $gray-d;
    height: $huge-spacing;
  }

  .content {
    &__action {
      &-bar {
        background: transparent;
        box-shadow: none;
        display: flex;
        justify-content: space-between;

        .button {
          &--page {
            box-shadow: 0 $small-spacing $default-spacing -.5rem rgba($black, .5);
          }
        }
      }

      .button {
        margin-left: $small-spacing;
      }
    }
  }
}

@import 'media-queries';