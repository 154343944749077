@media screen and (max-width: map-get($breakpoints, 'medium')) {
  .side-bar {
    @include  sidebar-mini-large;
  }
}

@media screen and (max-width: map-get($breakpoints, 'small')) {
  .side-bar {
    flex-direction: row;
    justify-content: space-between;
    max-height: unset;
    max-width: 100vw;
    min-height: auto;
    min-width: 100vw;
    overflow: unset;
    position: fixed;

    .logo {
      &__wrapper {
        z-index: 4;
      }

      &__sdm {
        display: none;
      }
    }

    + .content {
      &__wrapper {
        max-width: 100vw;
        padding-top: $huge-spacing;

        .table {
          max-width: 100%;
        }
      }
    }

    .form {
      &__search {
        left: 0;
        min-width: 100vw;

        &-wrapper {
          z-index: 5;
        }

        &--top-bar {
          + .button {
            &--close {
              z-index: 5;

              .icon {
                &::before {
                  font-size: $default-spacing;
                }
              }
            }
          }
        }

        .form {
          &__field-container {
            left: $small-spacing;
            width: calc(100vw - 5rem);
          }
        }
      }
    }

    .button {
      &--search-nav-min,
      &--nav-mobile {
        position: absolute;
      }

      &--search {
        &-nav-min {
          left: unset;
          right: $huge-spacing;
        }

        &-close {
          right: 0;
        }
      }

      &--nav-mobile {
        display: flex;
        justify-content: center;
        right: 0;

        .icon {
          &::before {
            font-size: 1.3rem;
          }
        }
      }

      .icon {
        &::before {
          font-size: 1.1rem;
        }
      }
    }
  }
}
