// Couleurs //

$transparent: transparent;
$inherit: inherit;
$white: #FFF;
$black: #000;
$gray-25: #252525;
$gray-33: #333;
$gray-40: #404040;
$gray-50: #505050;
$gray-60: #606060;
$gray-80: #808080;
$gray-90: #909090;
$gray-a: #aaa;
$gray-c: #ccc;
$gray-d: #ddd;
$gray-e: #eee;
$gray-fa: #fafafa;
$blue: #5f9edd;
$gray-nickel-lighter: #e2e2d5;
$gray-nickel-light: #b5b5a1;
$gray-nickel: #9d9b8e;
$gray-nickel-dark: #8e8c80;
$gray-nickel-darker: #404040;
$red-delagrave: #bd2c17;
$blue: #5f9edd;
$red: #fd2c39;
$green: #51a300;
$orange: #F08A24;

// Variables //

$color-delagrave-gray-lighter: $gray-nickel-lighter;
$color-delagrave-gray-light: $gray-nickel-light;
$color-delagrave-gray: $gray-nickel;
$color-delagrave-gray-dark: $gray-nickel-dark;
$color-delagrave-gray-darker: $gray-nickel-darker;
$color-delagrave-red: $red-delagrave;
$color-default: $gray-nickel-darker;
$color-success: $green;
$color-warning: $orange;
$color-alert: $red;

// Tableau des couleurs //

$colors: (
  'inherit': $inherit,
  'transparent': $transparent,
  'white': $white,
  'black': $black,
  'default': $color-default,
  'alert': $color-alert,
  'warning': $color-warning,
  'success': $color-success,
  'gray-25': $gray-25,
  'gray-33': $gray-33,
  'gray-40': $gray-40,
  'gray-50': $gray-50,
  'gray-60': $gray-60,
  'gray-80': $gray-80,
  'gray-90': $gray-90,
  'gray-a': $gray-a,
  'gray-c': $gray-c,
  'gray-d': $gray-d,
  'gray-e': $gray-e,
  'gray-fa': $gray-fa,
  'blue': $blue,
  'delagrave-gray-lighter': $color-delagrave-gray-lighter,
  'delagrave-gray-light': $color-delagrave-gray-light,
  'delagrave-gray': $color-delagrave-gray,
  'delagrave-gray-dark': $color-delagrave-gray-dark,
  'delagrave-gray-darker': $color-delagrave-gray-darker,
  'delagrave-red': $color-delagrave-red,
);