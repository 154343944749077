@import 'mixins';

.side-bar {
  background: $color-delagrave-gray-darker;
  color: $white;
  display: flex;
  flex-direction: column;
  max-width: 18rem;
  min-height: 100vh;
  min-width: 18rem;
  overflow: hidden;
  transition: min-width .25s, min-height .25s, max-width .25s;
  z-index: 10;

  .logo {
    &__wrapper {
      overflow: hidden;
    }
  }

  .form {
    &__search {
      left: 0;
      max-width: 16rem;
      min-height: 2.5rem;
      opacity: 1;
      overflow: hidden;
      position: absolute;
      top: .75rem;
      transition: top 0s ease 0s, opacity .25s ease .25s, right .25s ease .25s;

      &-wrapper {
        min-height: 4rem;
        position: relative;
        transition: min-height .25s;
      }
    }

    &__field-container {
      position: absolute;
      width: 100%;

      .form {
        &__field {
          color: $color-default;
          transition: background 0s;
        }
      }
    }
  }

  .button {
    &--search {
      left: -4rem;
      opacity: 0;
      position: relative;
      transition: opacity .25s, left .25s ease .25s;
    }

    &--search-close,
    &--nav-mobile {
      display: none;
    }
  }

  &--mini {
    @include sidebar-mini-large;
  }
}

@import 'media-queries';
