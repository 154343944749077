@media screen and (max-width: map-get($breakpoints, 'medium')) {
  .order-status {
    &__global {
      font-size: 1.4rem;
    }

    &__step {
      &-element {
        &--wip {
          .order-status {
            &__step-text,
            &__step-complement {
              font-size: .9rem;
            }
          }
        }
      }

      &-text {
        font-size: .9rem;
      }

      &-complement {
        font-size: .8rem;
      }
    }
  }
}

@media screen and (max-width: map-get($breakpoints, 'small')) {
  .order-status {
    &__global {
      font-size: $default-spacing;
    }

    &__step {
      flex-direction: column;

      &::after {
        display: none;
      }

      &-text,
      &-complement {
        font-size: .9rem;
      }

      &-element {
        width: 100%;

        &::before {
          display: none;
        }

        &:not(:last-of-type) {
          margin-bottom: $big-spacing;
        }

        &:not(.order-status__step-element--wip) {
          .icon {
            height: $huge-spacing;
          }
        }

        &--wip {
          .order-status {
            &__step-text,
            &__step-complement {
              font-size: 1rem;
            }
          }
        }
      }

      &-text {
        margin-top: 0;
      }
    }

    &__progress-bar {
      &::after {
        display: none;
      }
    }
  }
}