@media screen and (max-width: map-get($breakpoints, 'small')) {
  .form {
    &__field {
      line-height: 2.2rem;
      min-height: 2.2rem;

      &-container {
        &--review {
          margin-right: 0 !important;
        }
      }
    }

    &__review {
      flex-direction: column;
    }

    &__attachment-list-element {
      font-size: .8rem;
    }
  }
}