.layout {
  overflow: hidden;

  &--ui-hide {
    flex-direction: column;

    .side-bar {
      background: $color-delagrave-gray-darker;
      border-bottom: 3px solid $color-delagrave-red;
      max-width: 100%;
      min-height: auto;
      padding-bottom: $big-spacing;

      > *:not(.logo__wrapper) {
        display: none;
      }

      .logo {
        &__sdm {
          display: block;
          order: 0;
          padding-top: $big-spacing;
          text-align: center;

          .image {
            height: 5.5rem;
          }

          .logo {
            &__text {
              display: none;
            }
          }
        }
      }
    }

    .logo {
      font-size: 1.8rem;
      position: relative;
      top: 0;
      transition: unset;
      width: auto;

      &__baseline {
        color: $color-delagrave-gray-lighter;
        display: flex !important;
        font-size: .9rem;
        font-weight: bold;
        justify-content: center;
      }

      &__wrapper {
        align-items: flex-end;
        background: transparent;
        border-bottom: 0;
        justify-content: center;

        &::before {
          @include pseudo-element;
          background: $gray-33;
          height: 2px;
          left: calc(50% - 2.5rem);
          top: 2px;
          width: 5rem;
        }
      }

      &--part {
        &-1,
        &-2 {
          left: 0;
        }
      }
    }

    .content {
      &__wrapper {
        max-width: 100vw !important;
        min-height: calc(100vh - 13.2rem);
        position: relative;
      }

      &__navigation {
        display: none;
      }
    }
  }
}

@import 'media-queries';