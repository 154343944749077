@media screen and (max-width: map-get($breakpoints, 'small')) {
  .pagination {
    justify-content: center;
    padding-bottom: $tiny-spacing;
    padding-top: $tiny-spacing;

    .list {
      &__action-form,
      &__information {
        margin-left: auto;
        margin-right: auto;
        padding-bottom: $tiny-spacing;
        padding-top: $tiny-spacing;
      }
    }

    .button {
      &__text {
        display: none;
      }

      &--previous,
      &--next {
        .icon {
          &::before {
            font-size: $medium-spacing;
          }
        }
      }
    }

    &--bottom {
      .list {
        &__action-form {
          display: none;
        }

        &__pagination {
          margin-bottom: $medium-spacing !important;
        }
      }
    }
  }
}