.logo {
  font-size: $medium-spacing;
  line-height: 2rem;
  max-width: calc(100% - 2rem);
  overflow: hidden;
  position: absolute;
  top: .5rem;
  white-space: nowrap;

  &--part {
    &-1 {
      color: $white;
      left: $default-spacing;
      transition: width .25s, left .25s;
      width: 5.5rem;
    }

    &-2 {
      color: $color-delagrave-red;
      left: 6.5rem;
      transition: width .25s, left .25s;
      width: 4.5rem;
    }
  }

  &__wrapper {
    background: $color-delagrave-gray-light;
    border-bottom: 1px solid $color-delagrave-gray-darker;
    display: flex;
    font-weight: bold;
    max-height: $huge-spacing;
    min-height: $huge-spacing;
    padding: 0 $default-spacing;
    position: relative;
    transition: padding .25s;
    width: 100%;
  }

  &__baseline {
    display: none;
  }

  &__sdm {
    align-items: center;
    display: flex;
    flex-direction: column;
    order: 9;
    padding: $small-spacing $default-spacing;

    .image {
      height: $default-spacing;
      width: auto;
    }

    .logo {
      &__text {
        background: $color-delagrave-gray-darker;
        color: $color-delagrave-gray;
        font-size: .8rem;
        font-style: italic;
        opacity: 1;
        padding-top: .1rem;
        position: relative;
        top: -.2rem;
        transition: opacity .25s;
        white-space: nowrap;
      }
    }
  }

  &__user {
    margin-right: $big-spacing;
    max-width: 50%;

    .image {
      max-height: 10rem;
      max-width: 100%;
      width: auto;

      &--default {
        opacity: .2;
      }
    }
  }
}

@import 'media-queries';
