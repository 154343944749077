@media screen and (max-width: map-get($breakpoints, 'medium')) {
  .text {
    font-size: .9rem;

    &--chapo {
      font-size: 1rem;
      margin-bottom: $small-spacing;
    }
  }
}

@media screen and (max-width: map-get($breakpoints, 'small')) {
  .text {
    font-size: .8rem;

    &--chapo {
      font-size: .9rem;
    }

    &__value {
      font-size: .8rem;
    }
  }
}