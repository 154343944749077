@media screen and (max-width: map-get($breakpoints, 'medium')) {
  .layout {
    &--ui-hide {
      .side-bar {
        .logo {
          &--part-1,
          &--part-2 {
            left: 0;
            width: auto;
          }
        }
      }
    }
  }
}

@media screen and (max-width: map-get($breakpoints, 'small')) {
  .layout {
    flex-direction: column;

    &--ui-hide {
      .side-bar {
        flex-direction: column;
        padding-bottom: $default-spacing;

        .logo {
          &__sdm {
            padding-top: $default-spacing;

            .image {
              height: 3.6rem;
            }
          }
        }
      }

      .logo {
        font-size: 1.2rem;

        &__baseline {
          font-size: .7rem;
        }

        &__wrapper {
          min-height: auto;
          width: 100%;

          &::before {
            top: -1px;
          }
        }
      }

      .content {
        &__wrapper {
          padding-top: 8rem !important;
        }
      }
    }
  }
}