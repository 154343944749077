.table {
  display: flex;
  flex-direction: column;
  overflow-x: auto;
  min-width: 100%;

  &__row,
  &__column {
    display: flex;
  }

  &__column {
    justify-content: center;
    padding: 0 .75rem;
    position: relative;
    text-align: center;

    &--view-more {
      max-width: 4rem;
      min-width: 4rem;
    }

    &--checkbox {
      max-width: $huge-spacing;
      min-width: $huge-spacing;

      .form {
        &__field {
          line-height: $default-spacing;
          min-height: $default-spacing;
        }
      }
    }

    &--number {
      max-width: 5rem;
      min-width: 5rem;
    }

    &--sav {
      max-width: 6rem;
      min-width: 6rem;
    }

    &--order,
    &--id,
    &--date,
    &--version,
    &--price {
      max-width: 8rem;
      min-width: 8rem;
    }

    &--name,
    &--status,
    &--fullname,
    &--email {
      max-width: 100%;
      min-width: 15rem;
      width: 100%;
    }

    &--date,
    &--version {
      .form {
        &__field {
          font-size: .7rem;
          padding: 0;
        }
      }
    }

    &--type {
      max-width: 15rem;
      min-width: 15rem;
    }

    &--view-more,
    &--action {
      .button {
        &__text {
          display: none;
        }
      }
    }

    &--fullname {
      .text {
        &__value {
          &:not(:first-of-type) {
            margin-left: $small-spacing;
          }
        }
      }
    }

    &--action {
      display: flex;
      flex-shrink: 0;

      &::before {
        @include pseudo-element;
        height: 0;
        position: relative;
        width: 0;
      }

      .table {
        &__action {
          display: flex;
          justify-content: center;
          margin: 0 $tiny-spacing;
          max-width: 4rem;
          min-width: 4rem;
          text-align: center;

          &:first-of-type {
            margin-left: auto;
          }
        }
      }
    }

    &--alert {
      cursor: help;

      .text {
        &__value {
          color: $color-alert;
        }
      }
    }

    &--sort {
      display: flex;
      justify-content: center;

      &:hover,
      &:active,
      &:focus {
        color: $color-default !important;
        cursor: pointer;
      }

      &-up,
      &-down {
        &::after {
          @include pseudo-element;
          border-left: .4rem solid transparent;
          border-right: .4rem solid transparent;
          bottom: .4rem;
          height: 0;
          width: 0;
        }

      }

      &-up {
        &::after {
          border-bottom: .4rem solid $color-delagrave-red;
          border-top: 0;
        }
      }

      &-down {
        &::after {
          border-bottom: 0;
          border-top: .4rem solid $color-delagrave-red;
        }
      }
    }
  }

  &__row {
    width: 100%;

    &--header {
      border-bottom: 0 !important;

      .table {
        &__column {
          align-items: flex-end;
          color: $gray-80;
          font-size: .85rem;
          justify-content: center;
          padding: .75rem .75rem $default-spacing;

          &--checkbox {
            .form {
              &__field {
                margin-top: $tiny-spacing;
              }
            }
          }

          a {
            color: $gray-33;

            &:hover {
              color: $color-delagrave-red;
            }
          }

          .text {
            &__label {
              display: flex !important;
            }
          }

          .form {
            &__field-container {
              &--checkbox {
                flex-direction: column;
              }
            }
          }
        }
      }
    }

    &:not(.table__row--header) {
      background: $gray-fa;
      min-height: $huge-spacing;

      .table {
        &__column {
          align-items: center;
          background: $gray-fa;
          border-top: 1px solid $gray-d;
          padding-bottom: $small-spacing;
          padding-top: $small-spacing;

          &:not(:last-of-type){
            &::after {
              @include pseudo-element;
              background: $gray-c;
              height: $default-spacing;
              right: 0;
              width: 1px;
            }
          }

          &--order {
            font-weight: bold;
          }
        }
      }
    }

    &:not(.table__row--detail) {
      padding: 0;

      .text {
        &__label {
          display: none;
        }
      }
    }

    &--detail {
      display: none;
      min-height: auto !important;
      min-width: 100%;
      padding: $default-spacing $big-spacing !important;
    }
  }

  &__filter {
    background: $color-delagrave-gray;
    display: none;
    min-width: 100%;
    opacity: 1 !important;
    padding: $default-spacing $big-spacing 0 !important;

    &-button {
      max-width: 50%;
      width: 100%;

      &:first-of-type {
        margin-right: $default-spacing;
      }
    }

    .form {
      &__label {
        color: $white;
      }
    }

    .button {
      align-self: flex-end;
      margin-bottom: .1rem;
      max-height: 2.5rem;

      &:hover {
        background: $color-delagrave-gray-darker;
      }

      &--submit {
        &:hover,
        &:active,
        &:focus {
          background: $color-delagrave-gray-light;
        }
      }
    }
  }

  &:not(.table--with-expanded) {
    .table {
      &__row {
        &:nth-of-type(2n+1) {
          background: $white;

          .table {
            &__column {
              background: $white;
            }
          }
        }
      }
    }
  }

  &--with-expanded {
    .table {
      &__row {
        &:nth-of-type(4n+3),
        &:nth-of-type(4n+4) {
          background: $white !important;

          .table {
            &__column {
              background: $white !important;
            }
          }
        }
      }
    }
  }

  .icon {
    font-size: $default-spacing;

    &--alert {
      position: absolute;
      right: .4rem;
    }
  }

  .button {
    &--table-download {
      .icon {
        font-size: 1.2rem;
      }
    }
  }
}

@import 'media-queries';