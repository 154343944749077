@media screen and (max-width: map-get($breakpoints, 'medium')) {
  .content {
    &__navigation {
      .button {
        &--nav-min {
          display: none;
        }
      }
    }
  }
}

@media screen and (max-width: map-get($breakpoints, 'small')) {
  .content {
    padding: $default-spacing;

    &__wrapper {
      overflow: auto;
    }

    &__navigation {
      display: none;
    }

    .content {
      &__action {
        .button {
          &__text {
            display: none;
          }

          .icon {
            margin-right: 0;
          }
        }
      }
    }
  }
}