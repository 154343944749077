@media screen and (max-width: map-get($breakpoints, 'medium')) {
  .ev {
    &-direction {
      &--row-medium {
        flex-direction: row !important;
      }

      &--column-medium {
        flex-direction: column !important;
      }
    }

    &-justify {
      &--medium-center {
        justify-content: center !important;
      }

      &--medium-start {
        justify-content: flex-start !important;
      }

      &--medium-end {
        justify-content: flex-end !important;
      }

      &--medium-between {
        justify-content: space-between !important;
      }

      &--medium-around {
        justify-content: space-around !important;
      }

      &--medium-evenly {
        justify-content: space-evenly !important;
      }
    }

    &-align {
      &--medium-center {
        align-items: center !important;
      }

      &--medium-start {
        align-items: flex-start !important;
      }

      &--medium-end {
        align-items: flex-end !important;
      }

      &--medium-baseline {
        align-items: baseline !important;
      }

      &--medium-stretch {
        align-items: stretch !important;
      }
    }
  }

  .ev-wrap {
    &--medium {
      flex-wrap: wrap !important;
    }
  }

  .ev-nowrap {
    &--medium {
      flex-wrap: nowrap !important;
    }
  }
}

@media screen and (max-width: map-get($breakpoints, 'small')) {
  .ev {
    &-direction {
      &--row-small {
        flex-direction: row !important;
      }

      &--column-small {
        flex-direction: column !important;
      }
    }

    &-justify {
      &--small-center {
        justify-content: center !important;
      }

      &--small-start {
        justify-content: flex-start !important;
      }

      &--small-end {
        justify-content: flex-end !important;
      }

      &--small-between {
        justify-content: space-between !important;
      }

      &--small-around {
        justify-content: space-around !important;
      }

      &--small-evenly {
        justify-content: space-evenly !important;
      }
    }

    &-align {
      &--small-center {
        align-items: center !important;
      }

      &--small-start {
        align-items: flex-start !important;
      }

      &--small-end {
        align-items: flex-end !important;
      }

      &--small-baseline {
        align-items: baseline !important;
      }

      &--small-stretch {
        align-items: stretch !important;
      }
    }
  }

  .ev-wrap {
    &--small {
      flex-wrap: wrap !important;
    }
  }

  .ev-nowrap {
    &--small {
      flex-wrap: nowrap !important;
    }
  }
}