@media screen and (max-width: map-get($breakpoints, 'medium')) {
  .ev-text {
    &__align {
      &--medium-left {
        text-align: left;
        justify-content: left;
      }

      &--medium-right {
        text-align: right;
        justify-content: right;
      }

      &--medium-center {
        text-align: center;
        justify-content: center;
      }
    }
  }
}

@media screen and (max-width: map-get($breakpoints, 'small')) {
  .ev-text {
    &__align {
      &--small-left {
        text-align: left;
        justify-content: left;
      }

      &--small-right {
        text-align: right;
        justify-content: right;
      }

      &--small-center {
        text-align: center;
        justify-content: center;
      }
    }
  }
}