@media screen and (max-width: map-get($breakpoints, 'small')) {
  .logo {
    &__wrapper {
      border-bottom: 0;
      width: $huge-spacing;
    }

    &__user {
      margin-bottom: $default-spacing;
      margin-right: 0;
      max-width: 100%;
    }
  }
}