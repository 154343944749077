@media screen and (max-width: map-get($breakpoints, 'small')) {
  .ev-element {
    &__wrapper {
      .ev-element {
        max-width: 100% !important;
        min-width: 100% !important;
      }

      &--gutter {
        left: 0 !important;
        max-width: 100% !important;
        min-width: 100% !important;

        .ev-element {
          margin-left: 0 !important;
          margin-right: 0 !important;
        }
      }
    }
  }
}
