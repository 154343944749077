.icon {
  align-items: center;
  display: flex;
  height: $medium-spacing;
  justify-content: center;

  &--title {
    margin-right: $small-spacing;
  }

  &--expand {
    &::before {
      font-size: .7rem;
    }
  }

  &--alert {
    &::before {
      color: $color-alert;
    }
  }
}