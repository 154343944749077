.pagination {
  align-items: center;
  border-top: 1px solid $gray-d;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  min-height: 4rem;
  position: relative;

  &__element,
  &__list {
    align-items: center;
    display: flex;
  }

  &__list {
    @include typography-ellipsis;
    margin: 0 $default-spacing;
    max-width: 100%;
    position: relative;
  }

  &__element {
    min-width: 1rem;

    &--active {
      .button {
        color: $color-delagrave-red;
      }
    }
  }

  &--top {
    border-bottom: 3px solid $gray-50;
  }

  &--bottom {
    border-top: 3px solid $gray-50;
  }

  .button {
    &__text {
      font-size: .9rem;
      white-space: nowrap;
    }
  }
}

@import 'media-queries';