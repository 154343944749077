.breadcrumbs {
  align-items: center;
  display: flex;
  padding: 0 $default-spacing;

  &__element {
    font-size: .8rem;

    &:not(:last-of-type) {
      &::after {
        content: '/';
        margin: 0 $medium-spacing;
      }
    }

    &:last-of-type {
      color: $color-delagrave-red;
    }
  }
}

@import 'media-queries';