.navigation {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  position: relative;

  &__menu,
  &__menu-element {
    display: flex;
    flex-direction: column;
  }

  &__menu {
    position: relative;
    width: 100%;

    &--mini,
    &--default {
      left: 0;
      position: relative;
      top: 0;
      transition: left .25s;
    }

    &--default {
      border-bottom: 1px solid $gray-33;
      border-top: 1px solid $gray-33;
    }

    &-element {
      overflow: hidden;

      .icon {
        &::before {
          color: $white;
        }

        &:not(.icon--expand) {
          z-index: 2;
        }

        &--expand {
          &::before {
            color: $color-delagrave-red;
          }
        }
      }

      &--main {
        max-height: $huge-spacing;
        min-height: $huge-spacing;
        transition: max-height .25s;

        .icon {
          &--expand {
            opacity: 1;
            transition: opacity .25s ease .25s;
          }
        }

        &:hover,
        &:active,
        &:focus {
          background: $color-delagrave-gray-dark;

          .icon {
            &:not(.icon--expand) {
              background: $color-delagrave-red;
            }
          }
          .navigation {
            &__text-wrapper {
              color: $color-default;
              transition: color .25s;
            }
          }
        }
      }

      &--open {
        background: $color-delagrave-gray-dark;
        transition: max-height .25s;

        .navigation {
          &__sub {
            .navigation {
              &__text-wrapper {
                left: 0;
              }
            }
          }
        }

        .icon {
          &:not(.icon--expand) {
            background: $color-delagrave-red;
          }

          &--expand {
            margin-top: .1rem;
            transform: rotate(180deg);
          }
        }
      }
    }

    &-link {
      align-items: center;
      color: $white;
      display: flex;
      line-height: $huge-spacing;
      min-height: $huge-spacing;
      position: relative;
      white-space: nowrap;
      width: 100%;

      .icon {
        height: $huge-spacing;
        min-width: $huge-spacing;
        width: $huge-spacing;

        &--expand {
          position: absolute;
          right: 0;
          top: 0;
        }
      }
    }

    &-text {
      color: $white;
      font-size: 1.1rem;
      left: $huge-spacing;
      margin-right: auto;
      opacity: 1;
      padding-left: $default-spacing;
      position: absolute;
      top: 0;
      transition: left 0s ease 0s, opacity 0s ease 0s;
    }

    > .navigation {
      &__menu-element {
        &:not(:last-of-type) {
          border-bottom: 1px solid $color-delagrave-gray-darker;
        }
      }
    }

    &--mini {
      border-bottom: 1px solid $gray-33;
      border-top: 1px solid $gray-33;
      transition: left .25s;
      z-index: 2;

      .navigation {
        &__menu {
          &-element {
            background: $color-delagrave-gray-darker;
            overflow: visible;
            padding: 0;
            transition: max-height .15s;

            &--main {
              transition: max-height .15s ease .25s;

              .icon {
                &--expand {
                  opacity: 0;
                  transition: opacity .2s ease 0s;
                }
              }

              .navigation {
                &__text-wrapper {
                  align-items: center;
                  background: $color-delagrave-gray-darker;
                  display: flex;
                  height: $huge-spacing;
                  left: -9999px;
                  min-width: 0;
                  overflow: hidden;
                  padding-left: 0;
                  position: absolute;
                  right: -15rem;
                  transition: min-width .15s, width .15s, left 0s ease .25s, right 0s ease .25s;
                  width: 0;
                }

                &__menu-text,
                + .icon {
                  opacity: 0;
                  transition: opacity .15s ease .25s, left 0s ease .25s;
                }

                &__menu {
                  &-text {
                    left: -20rem;
                    position: relative;
                    transition: opacity .15s ease .25s, left 0s ease .25s;
                    white-space: nowrap;
                  }
                }

                &__sub {
                  .icon {
                    opacity: 0;
                    transition: opacity .25s;
                  }
                }
              }
            }

            &--open {
              background: $color-delagrave-red;
              transition: max-height .15s;

              .icon {
                &--expand {
                  opacity: 1;
                }

                &:not(.icon--expand) {
                  background: $color-delagrave-red;
                }
              }

              .navigation {
                &__text-wrapper {
                  left: $huge-spacing;
                  min-width: 15rem;
                  overflow: hidden;
                  right: 0;
                  transition: min-width .15s ease .25s, width .15s ease .25s, left 0s, right 0s ease .25s;
                  width: 15rem;
                }

                &__menu-text,
                + .icon {
                  opacity: 1;
                  transition: opacity .15s ease .25s, left 0s ease .25s;
                }

                &__menu {
                  &-text {
                    left: 0;
                    transition: opacity .15s ease .25s, left 0s ease .25s;
                  }
                }

                &__sub {
                  .icon {
                    opacity: 1;
                    transition: opacity .25s ease .3s;
                  }

                  .navigation {
                    &__menu-element {
                      &:last-of-type {
                        .navigation {
                          &__text-wrapper {
                            border-bottom: 1px solid $gray-50;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }

            &--single {
              .navigation {
                &__text-wrapper {
                  left: -9999px;
                  transition: min-width .15s, width .15s, left 0s ease .25s, right 0s ease .25s;
                }
              }
            }
          }
        }
      }

      .icon {
        &--expand {
          transform: rotate(90deg) !important;
        }
      }
    }
  }

  &__text-wrapper {
    align-items: center;
    display: flex;
    flex-grow: 2;
    height: $huge-spacing;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: min-width .15s ease 0s, width .15s ease 0s, left 0s ease 0s, right 0s ease 0s;
  }

  &__sub {
    display: flex;
    flex-direction: column;
    position: relative;

    .navigation {
      &__menu {
        &-element {
          align-items: center;
          flex-direction: row;
          max-height: $huge-spacing;
          min-height: $huge-spacing;
          padding: 0;
        }

        &-link {
          color: $gray-a;
          font-size: .9rem;

          &:hover,
          &:active,
          &:focus {
            color: $white;

            .navigation {
              &__menu-text {
                color: $white;
              }
            }

            .icon {
              &::before {
                color: $white;
              }
            }
          }
        }

        &-text {
          color: $color-delagrave-gray-lighter;
          font-size: $default-spacing;
          padding-left: $default-spacing;
        }
      }
    }
  }

  &--account {
    display: flex;
    flex-direction: column;
    margin-left: $huge-spacing;
    margin-right: $default-spacing;
    max-height: $huge-spacing;
    max-width: 16rem;
    min-width: 16rem;
    overflow: hidden;
    position: absolute;
    right: 2em;
    transition: max-height .25s;

    .button {
      min-height: $huge-spacing;
      z-index: 5;
    }

    .navigation {
      &__dropdown {
        background: $color-delagrave-gray-darker;
        z-index: 4;

        &-element {
          color: $white;
        }

        &--open {
          transition: max-height .25s;
        }
      }

      &__menu {
        &-link {
          &:hover {
            background: $color-delagrave-gray-dark;

            .icon {
              background: $color-delagrave-red;
            }
          }
        }
      }
    }
  }

  &__menu-link,
  &__sub {
    z-index: 2;
  }

  &__overlay {
    display: none;
    height: 100vh;
    position: fixed;
    right: 0;
    top: 0;
    width: calc(100vw - 4rem);

    &--account {
      width: 100vw;
      z-index: 3;
    }
  }

  &__mobile-bar {
    display: none;
  }
}

@import 'media-queries';
