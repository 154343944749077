// Marges internes et externes des "sections" //

$ev-section-padding: 0;
$ev-section-margin: $default-spacing 0;

.section {
  background: $white;
  box-shadow: 0 $small-spacing $default-spacing -.5rem rgba($black, .5);
  margin: $ev-section-margin;
  max-width: 100%;
  overflow: hidden;
  padding: $ev-section-padding;

  &__header {
    background: $color-delagrave-gray-light;
    min-height: $huge-spacing;
    padding: 0;
    height: 100%;

    .button {
      min-height: $huge-spacing;
      padding: 0 $default-spacing !important;

      &:not(:first-of-type) {
        margin-left: .1rem;
      }
    }

    .icon {
      height: $huge-spacing;
    }

    &-button-wrapper {
      display: flex;
    }
  }

  &__content {
    min-width: 100%;
    padding: $default-spacing $big-spacing;

    &--list-chapo {
      background: $gray-fa;
      border-bottom: 1px solid $gray-c;
      padding-bottom: $default-spacing !important;

      .text {
        &__wrapper {
          .text {
            &__label {
              &::before {
                color: $color-delagrave-red;
              }
            }
          }
        }

        &__label,
        &__value {
          font-size: $default-spacing;
        }

        &__label {
          font-weight: bold;
          margin-right: $small-spacing;
        }

        &__value {
          &:not(:last-of-type) {
            &::after {
              color: $color-delagrave-red;
              content: '/';
              margin: 0 $small-spacing;
            }
          }
        }
      }
    }

    &--table {
      padding: 0 !important;
    }
  }

  &--dashboard {
    &-link {
      .button {
        background: $white;
        font-size: $medium-spacing;
        justify-content: flex-start;
        padding: $big-spacing $big-spacing $default-spacing;
        text-align: center;
        width: 100%;

        &__text {
          color: $color-default;
          transition: color .25s;

          &:first-of-type {
            font-size: 1.3rem;
            margin-bottom: $small-spacing;
            margin-top: $medium-spacing;
          }

          &--value {
            color: $color-delagrave-red;
            font-size: 2.4rem;
            margin-top: auto;
          }
        }

        .icon {
          height: 5rem;
          margin-right: 0;

          &::before {
            color: $color-delagrave-red;
            font-size: 5rem;
          }
        }

        &:hover,
        &:active,
        &:focus {
          background: $color-delagrave-red;

          .button {
            &__text {
              color: $white;
              transition: color .25s;
            }
          }

          .icon {
            &::before {
              color: $white;
            }
          }
        }
      }
    }
  }

  &__table {
    overflow-x: auto;
  }
}

@import 'media-queries';