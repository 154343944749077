@mixin sidebar-mini-large() {
  max-width: $huge-spacing;
  min-width: $huge-spacing;
  transition: min-width .25s, min-height .25s, max-width .25s;

  .form {
    &__search {
      background: $color-delagrave-gray;
      display: flex;
      justify-content: center;
      left: $huge-spacing;
      margin: 0;
      min-height: $huge-spacing;
      min-width: calc(100vw - 3rem);
      opacity: 0;
      padding: .4rem 0;
      position: fixed;
      top: -4rem;
      transition: top 0s ease 0s, opacity .25s ease .25s, right .25s ease .25s;
      z-index: 6;

      &-wrapper {
        min-height: $huge-spacing;
        transition: min-height .25s;
      }

      .form {
        &__field {
          color: $color-default;
          line-height: 2.2rem;
          max-height: 2.2rem;
          min-height: 2.2rem;
          transition: background .25s;

          &-container {
            margin: 0;
            right: 25%;
            width: 50%;
          }
        }

        &__submit {
          max-width: 2.2rem;
          min-height: 2.2rem;
          min-width: 2.2rem;
        }
      }

      + .button {
        &--close {
          top: -4rem;
          transition: top .25s;
        }
      }

      &--top-bar {
        opacity: 1;
        top: 0;
        transition: top .25s, opacity .2s;

        + .button {
          &--close {
            top: $tiny-spacing;
            transition: top .25s;

            .icon {
              &::before {
                font-size: 1.4rem;
              }
            }
          }
        }
      }
    }
  }

  + .content {
    &__wrapper {
      max-width: calc(100vw - 3rem);
      transition: max-width .25s;

      .table {
        max-width: calc(100vw - 7rem);
      }
    }
  }

  .logo {
    &--part {
      &-1,
      &-2 {
        transition: width .25s, left .25s;
      }

      &-1 {
        left: $small-spacing;
        width: $default-spacing;
      }

      &-2 {
        left: $medium-spacing;
        width: .9rem;
      }
    }

    &__wrapper {
      padding: 0 .75rem;
      transition: padding .25s;
    }

    &__sdm {
      .logo {
        &__text {
          opacity: 0;
        }
      }
    }
  }

  .navigation {
    &__menu {
      &--default {
        left: -18rem;
        transition: left .25s;
      }
    }
  }

  .icon {
    &::before {
      color: $white;
    }

    &:not(.icon--expand) {
      &::before {
        font-size: $default-spacing;
      }
    }
  }

  .button {
    &--search {
      left: 0;
      opacity: 1;
      transition: opacity .25s ease .2s, left .25s ease .2s;

      &-close {
        display: flex;
        position: fixed;
      }
    }

    &--close {
      height: 2.5rem;
      right: $default-spacing;
      top: $tiny-spacing;
      width: 2.5rem;
    }
  }
}
