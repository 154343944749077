.button {
  align-items: center;
  background: $color-delagrave-gray-darker;
  color: $white;
  display: flex;
  font-size: 1rem;
  justify-content: center;
  min-height: 2.4rem;
  padding: $small-spacing $medium-spacing;
  transition: background .25s, color .25s;

  .icon {
    &::before {
      transition: color .25s;
    }
  }

  &__text {
    margin-left: .75rem;
  }

  &:hover,
  &:active,
  &:focus {
    background: $color-delagrave-gray;
    outline: 0;
    transition: background .25s;
  }

  &--gray {
    background: $gray-50;

    &:hover,
    &:active,
    &:focus {
      background: $color-delagrave-red;
    }
  }

  &--delagrave-red {
    background: $color-delagrave-red;
  }

  &--alert,
  &--delete {
    background: $color-alert;
  }

  &--icon {
    background: transparent;
    height: $huge-spacing;
    padding: 0;
    width: $huge-spacing;

    &:hover,
    &:active,
    &:focus {
      background: transparent;
    }

    .button {
      &__text {
        margin-left: 0;
      }
    }

    &.button {
      &--blue {
        color: $blue;
        transition: color .25s;

        .icon {
          &::before {
            color: $blue;
          }
        }

        &:hover,
        &:active,
        &:focus {
          color: $color-default;
          transition: color .25s;

          .icon {
            &::before {
              color: $color-default;
            }
          }
        }
      }
    }

    &-only {
      .icon {
        margin: 0;
      }

      .button {
        &__text {
          margin: 0;
        }
      }
    }
  }

  &--text {
    background: transparent !important;
    color: $color-default;
    padding: 0 !important;

    &:hover,
    &:active,
    &:focus {
      background: transparent;
      color: $color-delagrave-red;
      transition: color .25s;

      .icon {
        &::before {
          color: $color-delagrave-red;
        }
      }
    }

    .icon {
      &::before {
        color: $color-default;
        font-size: 1.1rem;
      }
    }

    &.button {
      &--blue {
        color: $blue;

        .icon {
          &::before {
            color: $blue;
          }
        }

        &:hover,
        &:active,
        &:focus {
          color: $color-default;

          .icon {
            &::before {
              color: $color-default;
            }
          }
        }
      }

      &--delagrave-red {
        color: $color-delagrave-red;

        .icon {
          &::before {
            color: $color-delagrave-red;
          }
        }

        &:hover,
        &:active,
        &:focus {
          color: $color-default;

          .icon {
            &::before {
              color: $color-default;
            }
          }
        }
      }
    }
  }

  &--return {
    padding: 0 0 0 $small-spacing !important;
  }

  &--filter {
    color: $color-delagrave-gray-darker;

    .icon {
      &::before {
        color: $color-delagrave-gray-darker;
      }
    }

    &:hover,
    &:active,
    &:focus {
      color: $white;

      .icon {
        &::before {
          color: $white;
        }
      }
    }
  }

  &--account {
    font-size: .9rem;
    justify-content: flex-end;
    padding-left: $default-spacing;
    padding-right: $default-spacing;
    width: auto;

    .button {
      &__text {
        @include typography-ellipsis;
        margin-right: $small-spacing;
        max-width: 100%;
        text-align: right;
      }
    }

    .icon {
      &::before {
        color: $color-delagrave-gray;
        font-size: 2.2rem;
      }
    }
  }

  &--close {
    position: absolute;
    right: $default-spacing;
    top: $default-spacing;
    z-index: 7;
  }

  &--section {
    &-hide {
      .icon {
        &::before {
          font-size: $default-spacing;
        }
      }
    }
  }

  &--table {
    &-action {
      font-size: .85rem;
      min-height: $big-spacing;
      padding: $tiny-spacing $small-spacing;
      width: 100%;
    }

    &-icon-only {
      background: transparent;
      color: $color-delagrave-red;
      transition: color .25s;

      .icon {
        &::before {
          color: $color-delagrave-red;
        }
      }

      &:hover,
      &:active,
      &:focus {
        background: transparent;
        color: $color-default;
        transition: color .25s;

        .icon {
          &::before {
            color: $color-default;
          }
        }
      }

      &.button {
        &--alert {
          .icon {
            &::before {
              color: $color-alert;
            }
          }
        }
      }
    }
  }

  &--nav-min {
    .icon {
      &::before {
        color: $color-default;
        font-size: $default-spacing;
      }
    }

    &:hover,
    &:active,
    &:focus {
      .icon {
        &::before {
          color: $color-delagrave-red;
        }
      }
    }
  }

  &--pagination {
    font-weight: bold;
    height: $huge-spacing;
    padding: 0 $tiny-spacing !important;

    &-nav {
      .icon {
        margin: 0;
        width: $medium-spacing;

        &::before {
          font-size: $default-spacing;
        }
      }

      &.button {
        &--previous {
          .button {
            &__text {
              margin-left: $tiny-spacing;
            }
          }
        }

        &--next {
          .button {
            &__text {
              margin-right: $tiny-spacing;
            }
          }
        }

        &--inactive {
          color: $gray-c;
          cursor: initial;

          .icon {
            &::before {
              color: $gray-c;
            }
          }
        }
      }
    }
  }
}

@import 'media-queries';