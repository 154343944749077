$fa-font-path: "assets/fonts";

// Font - Din Family

@font-face {
    font-family: 'din-regular';
    font-style: normal;
    font-weight: normal;
    src: url('../../../fonts/font-din/d-din-webfont.woff2') format('woff2'),
         url('../../../fonts/font-din/d-din-webfont.woff') format('woff'),
         url('../../../fonts/font-din/d-din-webfont.ttf') format('truetype');
}

@font-face {
    font-family: 'din-bold';
    font-style: normal;
    font-weight: bold;
    src: url('../../../fonts/font-din/d-din-bold-webfont.woff2') format('woff2'),
         url('../../../fonts/font-din/d-din-bold-webfont.woff') format('woff'),
         url('../../../fonts/font-din/d-din-bold-webfont.ttf') format('truetype');
}

@font-face {
    font-family: 'din-italic';
    font-style: italic;
    font-weight: normal;
    src: url('../../../fonts/font-din/d-din-italic-webfont.woff2') format('woff2'),
         url('../../../fonts/font-din/d-din-italic-webfont.woff') format('woff'),
         url('../../../fonts/font-din/d-din-italic-webfont.ttf') format('truetype');
}

body {
  color: $color-default;
  font-family: 'din-regular';
}
